<template>
  <component
    :is="to ? nuxtLink : 'div'"
    :to="to"
    :class="[
      $style['card'],
      {[$style['card--contain']]: contain },
      {[$style['card--reveal']]: reveal },
      {[$style[`card--${background}`]]: background }
    ]">
    <div v-if="image" :class="$style['card__image']">
      <NuxtImg format="webp" :src="image" loading="lazy" :alt="alt" />
    </div>
    <div v-if="!image && !!$slots.image" :class="$style['card__image']">
      <slot name="image" />
    </div>
    <div :class="$style['card__content']">
      <div v-if="title" :class="$style['card__title']">
        {{ title }}
      </div>
      <slot />
    </div>
    <div v-if="!!$slots.actions" :class="$style['card__actions']">
      <slot name="actions" />
    </div>
  </component>
</template>
<script lang="ts" setup>
const nuxtLink = resolveComponent('nuxt-link')
interface Props {
  image?: string | null
  to?: object | null,
  alt?: string | undefined,
  title?: string | undefined,
  reveal?: boolean,
  contain?: boolean,
  background?: 'dark' | 'light' | null
}
withDefaults(defineProps<Props>(), {
  image: null,
  to: null,
  contain: false,
  background: null,
  alt: undefined,
  title: null
})
</script>
<style lang="scss" module>
.card {
  background-color: var(--secondary-surface);
  color: var(--secondary-on-surface);
  display: inline-flex;
  flex-direction: column;
  box-shadow: var(--box-shadow-elevation-1);
  text-decoration: none;
  transition: all var(--duration-micro-fast) var(--easing-transition);
  overflow: hidden;
  position: relative;

  &__title {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-medium);
    font-family: var(--font-family-heading);
    text-transform: uppercase;
    margin-bottom: 0.5rem;
  }

  &__image {
    background-color: var(--background-subtle);
    display: flex;
    width: 100%;
    transition: transform var(--duration-micro-normal) var(--easing-transition);
    transform-origin: 100% 100%;

    & > * {
      aspect-ratio: 16/9;
      width: 100%;
      object-fit: cover;
    }
  }

  &__content {
    padding: var(--spacing-l);
    height: auto;
    flex: 1;
    transition: transform var(--duration-micro-normal) var(--easing-transition);
    transform-origin: 100% 100%;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    gap: var(--spacing);
    padding: var(--spacing);
    transform: translateY(100%);
    position: absolute;
    bottom: 0;
    transition: transform var(--duration-micro-normal) var(--easing-transition);
    width: 100%;
  }

  &--light {
    .card__image {
      background-color: var(--light-color-80);
    }
  }

  &--dark {
    .card__image {
      background-color: var(--dark-color-30);
    }
  }

  &--contain {
    .card__image {
      padding: var(--spacing);
      width: 100%;

      & > * {
        object-fit: contain;
      }
    }
  }

  &[href]:not(.card--reveal) {
    &:hover {
      color: var(--color-primary);
      box-shadow: var(--box-shadow-elevation-3);
    }

    &:focus-visible {
      box-shadow: 0 0 0 2px var(--secondary-on-surface);
    }

    &:hover .card__actions {
      // height: auto;
      transform: translateY(0);
    }

    &:hover :is(.card__image, .card__content) {
      transform: translateY(calc(var(--spacing-xxxxl) * -1));
    }
  }

  &--reveal,
  &:not([href]) {
    .card__image,
    .card__content,
    .card__actions {
      transform: none;
      position: static;
    }
  }

}
</style>
